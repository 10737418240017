<template>
  <div class="box flex h-full flex-row divide-x rounded-md shadow-xl">
    <!--#region left Nav -->
    <div class="flex h-full w-64 flex-col transition-all">
      <div class="flex flex-shrink-0 flex-col px-5 py-3">
        <div class="text-2xl font-bold capitalize-first">
          {{ $t('enums.invoicing.docTypes.' + invoice.doc_type) }}
        </div>
        <h2 class="-m-1 text-sm font-medium">
          {{ invoice.funeral_home.name }}
        </h2>
        <div class="mt-2">
          <StatusInput
            v-model="invoice.status"
            :options="statusOptions"
            disabled
          />
        </div>
      </div>
      <div class="flex min-h-0 flex-1">
        <div
          class="scrollbar-custom flex flex-1 flex-col gap-3 overflow-auto px-5 pb-3"
        >
          <template v-if="routeInvoiceId !== invoice.id">
            <LoadingComponent :display-label="false" />
          </template>
          <template v-else>
            <InvoicingShowInformation :invoice="invoice" lock-actions />
          </template>
        </div>
      </div>
    </div>
    <!--#endregion -->

    <!--#region right   -->
    <div class="flex h-full max-h-full min-h-0 flex-1 flex-col">
      <div class="min-h-0 flex-1">
        <div class="mx-3 flex flex-shrink-0 justify-between border-b py-3">
          <div class="flex flex-row items-center gap-x-5">
            <BaseButton
              :icon="'chevron-left'"
              :title="$t('button.back')"
              size="small"
              inverse-icon-order
              @click="() => $router.push({ name: 'invoice_info_route' })"
            />
          </div>
        </div>
        <div class="min-h-0 flex-1 px-3">
          <TheInvoiceWizardFinalInformationStep
            v-model:invoice="invoice"
            :accounting-diaries="metadata.account_diaries_settings"
          />
        </div>
      </div>
      <div class="flex shrink-0 justify-end border-t p-3">
        <BaseButton
          :title="$t('button.save')"
          icon="paper-plane"
          @click="updateInvoice"
        />
      </div>
    </div>
    <!--#endregion -->
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import InvoicingShowInformation from '@/views/invoicing/show/tabs/information/components/information/InvoicingShowInformation'
import LoadingComponent from '@c/TheLoadingComponent.vue'
import Status from '@/assets/enums/invoicing/Status'
import BaseButton from '@c/addf-package/components/BaseButton/BaseButton.vue'
import StatusInput from '@c/addf-package/components/status/StatusInput.vue'
import TheInvoiceWizardFinalInformationStep from '@/views/invoicing/form/steps/components/TheInvoiceWizardFinalInformationStep.vue'

export default {
  name: 'TheInvoicingEdit',
  components: {
    TheInvoiceWizardFinalInformationStep,
    StatusInput,
    BaseButton,
    LoadingComponent,
    InvoicingShowInformation
  },
  props: {
    invoicing_id: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapGetters({
      invoice_doc_types: 'metadata/getInvoiceDocTypes',
      invoice: 'invoicing/getInvoice',
      metadata: 'invoicing/getInvoiceMetadata',
      errors: 'auth/getErrors'
    }),
    validated() {
      return this.invoice.reference && this.invoice.reference.length
    },
    statusOptions() {
      let available_statuses =
        this.invoice_doc_types.find(
          (type) => type.id === this.invoice?.doc_type
        )?.available_statuses ?? []
      if (this.invoice.status !== Status.DRAFT) {
        return available_statuses.filter((item) => item.id !== Status.DRAFT)
      }
      return available_statuses
    },
    routeInvoiceId() {
      return this.invoicing_id
    }
  },
  methods: {
    ...mapActions({
      processUpdateInvoiceAction: 'invoicing/updateInvoice'
    }),
    updateInvoice() {
      this.processUpdateInvoiceAction({
        id: this.invoice.id,
        invoice: this.invoice
      }).then((_response) => {
        this.$h.toastSuccess(this.$t('global.saved'))
      })
    }
  }
}
</script>
